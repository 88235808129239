.app {
  position: relative;
 padding: 2%;
}
a.btn {
  font-size: 22px;
  font-weight: 700;
}

.btn {
  font-size: 22px;
  font-weight: 700;
}

.btn:hover {
  background-color:lightgrey;
}

h1{
text-align: center;
margin-top:60px ;
}

h2{
  text-align: right;
  margin-top:60px ;
  }

  h3{
    text-align: right;
   
    }

.logout{
color: #f44336;
margin: 8px;
text-decoration: none;
}
.logout:hover{
  font-size: 18px;
  }

.buyForm input{ 
  width:100px;
  margin: 10px;
  padding: 14px 17px 14px 10px;
  font-size: 22px;
}

select{ 
  width:30%;
  
  padding: 14px 17px 14px 10px;
  font-size: 22px;
}

 input {
  width: 100%;
  padding: 14px 17px 14px 10px;
  font-size: 22px;
}
.app label{
  font-size: 22px;
}
input[type="submit"] {
  width: auto;
  background-color: #ff9800;
  color: white;
  border: 0;
  padding: 10px 17px 10px 17px;
}

#excel {
  width: auto;
  background-color: #00831c;
  color: white;
  border: 0;
  margin: 10px;
  padding: 10px 17px 10px 17px;
}


#pdf {
  width: auto;
  background-color: #b60000;
  color: white;
  border: 0;
  margin: 10px;
  padding: 10px 17px 10px 17px;
}

button {
  width: auto;
  background-color: #ff9800;
  color: white;
  border: 0;
  margin: 10px;
  padding: 10px 17px 10px 17px;
}
input[type="submit"]:hover{
  background-color: #af6900;
  cursor: pointer;

}
.imglink{
color: darkslategray;
  padding-right: 1.2%;
}
.icon{
  width:50px;
  height: 50px;
  border-radius: 30px;
}
.icon:hover{
 border:1px solid #999;
}
.username{
  cursor: pointer;
}
.username:hover{
  color:#999;
  
}

.title{
 color:darkslategray;
 font-size: 20px;
 font-weight: 500;
}

.icon{
  width:50px;
  height: 50px;
  border-radius: 30px;
}
p{
  font-size: 22px;
}
p.btn.default-btn {
  color: #f44336;
  font-weight: 700;
  text-decoration: underline;
  
}


.uorder{
  text-align: center;
}



p.btn.default-btn:hover{

cursor: pointer;
  
}

.list {
 display:grid;
place-items: center;
}

img{
  width: 200px;;
  height:200px;
}
.products{
 margin: 20px;
 border: solid 4px darkslategray  ;
 border-radius: 10px;
 width: 300px;
   
    display: flex;
    flex-direction:column;
    align-items: center;
    
 
}


table, th, td {
 
  text-align: center;

}

.container{

  display:grid;
  place-items: center;
}

.container1{
  overflow-x:auto;
  
  
}







.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  text-align: center;
}
 
.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #f44336;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.confirm{
  width:300px;
  height: 50px;
  margin: 10px;
  font-size: 22px;
}


.nav-link{
  color:gray;
  font-weight: bold;
  
}


.nav-link:hover{
  color:#000;
  font-size: 19px;
}

.flex{
  display: flex;
}
.excel{
  background-color: green;
}
 


.insertF input{
  margin:0px 10px 20px 10px;
  width: 100%;
  height:60px;
}

.insertF select{
  margin:0px 10px 20px 10px;
  width: 100%;
  height:60px;
}


.insertF input[type="submit"] {
  margin:0px 10px 20px 10px;
  width: 90px;
  height:60px;
}
  
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;

  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.container2{
  overflow-x:auto;
 display: flex;

}


.hide{
  
 display: none;

}

.date{
  
  width: 70%;
 
 }

 .hide{
  
  display: none;
 
 }

 .head_link{
  width:'96%';
  display:'flex';
  margin:'2%';
  padding:2;
 }
 .head_link:hover{
  background-color:gray;
 }